// staff_permissions
export const STAFF_PERMISSIONS = "staff_permissions";
// manufacturing permissions
export const MANUFACTURING_CREATE = "manufacturing_create";
export const MANUFACTURING_READ = "manufacturing_read";
export const MANUFACTURING_UPDATE = "manufacturing_update";
export const MANUFACTURING_EXECUTE = "manufacturing_execute";
// inventory permissions
export const INVENTORY_CREATE = "inventory_create";
export const INVENTORY_READ = "inventory_read";
export const INVENTORY_UPDATE = "inventory_update";
export const INVENTORY_EXECUTE = "inventory_execute";
// sales permissions
export const SALES_CREATE = "sales_create";
export const SALES_READ = "sales_read";
export const SALES_UPDATE = "sales_update";
export const SALES_EXECUTE = "sales_execute";