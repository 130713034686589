import * as actionTypes from "../actions/actionTypes";
import { getCookie } from "../utilities/cookieUtils";

const initialState = {
    isAuthenticated: false,
    employee: null, // Initialize employee as null
    accessToken: getCookie('accessToken'), // Rename token to authAccessToken
    refreshToken: getCookie('refreshToken'),
    userPermissions: [],
    loading: false,
    error: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.LOGIN_SUCCESS:
            const { employee, accessToken, refreshToken, userPermissions } = action.payload; // Extract employee from payload
            return {
                ...state,
                isAuthenticated: true,
                employee: {
                    ...state.employee,
                    ...employee,
                },
                accessToken,
                refreshToken,
                userPermissions,
                loading: false,
                error: null
            };
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case actionTypes.SET_TOKEN_AND_EMPLOYEE_FROM_COOKIE:
            return {
                ...state,
                isAuthenticated: true,
                employee: { ...action.payload.employee },
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                userPermissions: action.payload.userPermissions,
                loading: false,
                error: null
            };
        case actionTypes.UPDATE_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.accessToken,
                loading: false,
                error: null
            };
        case actionTypes.LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default authReducer;