import api from './api';
import {setCookie} from '../utilities/cookieUtils';
import {updateAccessToken} from "../actions/authActions";

const refreshToken = async (dispatch) => {
    try {
        // Make a POST request to your backend's refresh token endpoint
        const response = await api.post('/auth/refresh_token');
        if (response.status !== 200) {
            throw new Error('Failed to refresh token');
        }
        // Extract the new access token from the response
        const accessToken = response.data.accessToken;
        // Store the new access token in a cookie
        setCookie('accessToken', accessToken);
        
        // Dispatch action to update Redux state with new access token
        dispatch(updateAccessToken(accessToken));
    } catch (error) {
        // Handle token refresh failure
        console.error('Error refreshing token:', error);
        // throw error; // Rethrow the error for further handling by calling code
    }
};

export default refreshToken;