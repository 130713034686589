import * as actionTypes from '../actions/actionTypes';

const initialState = {
    notifications: [],
    success: null,
    error: null
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.payload]
            };
        case actionTypes.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.payload)
            };
        case actionTypes.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications: []
            };
        case actionTypes.SET_SUCCESS_NOTIFICATION:
            return {
                ...state,
                success: action.payload
            };
        case actionTypes.SET_ERROR_NOTIFICATION:
            return {
                ...state,
                error: action.payload
            };
        case actionTypes.CLEAR_SUCCESS_NOTIFICATION:
            return {
                ...state,
                success: null
            };
        case actionTypes.CLEAR_ERROR_NOTIFICATION:
            return {
                ...state,
                error: null
            };
        default:
            return state;
    }
};

export default notificationReducer;