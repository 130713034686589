import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { isValidPassword } from "../../utilities/passwordVaildor";

const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return `(${phoneNumber}`;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const DynamicInput = ({ numberOfInputs, values, placeholders, onSubmit, className, onInputChange, isRegistration, isEdit, isLogin }) => {
    const initialFormData = Array.isArray(values) ? values.map(value => value.toString()) : Array(numberOfInputs).fill('');
    const [formData, setFormData] = useState(initialFormData);
    const [showPassword, setShowPassword] = useState(Array(numberOfInputs).fill(false));
    const [errorMessages, setErrorMessages] = useState(Array(numberOfInputs).fill(''));
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    
    const handleInputChange = (index, event) => {
        const newValues = [...formData];
        newValues[index] = event.target.value;
        if (placeholders[index]?.toLowerCase().includes('phone')) {
            newValues[index] = formatPhoneNumber(event.target.value);
        }
        setFormData(newValues);
        onInputChange(event, index);
        
        if (placeholders[index]?.toLowerCase().includes('password')) {
            setErrorMessages((prevErrors) => {
                const newErrors = [...prevErrors];
                newErrors[index] = isValidPassword(event.target.value) ? '' : 'Password does not meet criteria';
                return newErrors;
            });
        }
    };
    
    const handleDateChange = (index, date) => {
        const newValues = [...formData];
        newValues[index] = date ? date.toISOString().split('T')[0] : ''; // Store date as ISO string (YYYY-MM-DD)
        setFormData(newValues);
        onInputChange({ target: { value: date ? date.toISOString().split('T')[0] : '' } }, index);
    };
    
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const buffer = event.target.result;
                setFile(buffer);
                setPreview(URL.createObjectURL(selectedFile)); // Set the preview URL
            };
            reader.readAsArrayBuffer(selectedFile); // Read the file as an ArrayBuffer
        }
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        const formattedData = formData.map((item, index) => {
            if (placeholders[index]?.toLowerCase().includes('phone')) {
                return item; // Save formatted phone number to the backend
            }
            return item;
        });
        onSubmit(formattedData, file);
    };
    
    const togglePasswordVisibility = (index) => {
        const updatedShowPassword = [...showPassword];
        updatedShowPassword[index] = !updatedShowPassword[index];
        setShowPassword(updatedShowPassword);
    };
    
    const passwordCriteria = "Password must be between 8 and 32 characters, contain at least one letter, one number, and one special character.";
    
    return (
        <Box
            className={className}
            sx={{
                maxWidth: '800px',
                margin: '0 auto',
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
            }}
        >
            <Box component="form" onSubmit={handleSubmit} className="modern-form">
                <Grid container spacing={2} className={isLogin ? 'one-column' : 'two-column'}>
                    {Array.from({ length: numberOfInputs }).map((_, index) => (
                        <Grid item xs={12} sm={isLogin ? 12 : 6} key={index}>
                            {placeholders[index]?.toLowerCase().includes('password') ? (
                                <Box sx={{ position: 'relative' }}>
                                    {(isRegistration || isEdit) && errorMessages[index] && (
                                        <Typography variant="body2" color="error" sx={{ mb: 1 }}>
                                            {errorMessages[index]}
                                        </Typography>
                                    )}
                                    <TextField
                                        type={showPassword[index] ? 'text' : 'password'}
                                        id={`input-${index}`}
                                        name={`input-${index}`}
                                        value={formData[index] || ""}
                                        label={placeholders[index] || `Input ${index + 1}`}
                                        onChange={(event) => handleInputChange(index, event)}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => togglePasswordVisibility(index)}
                                                        edge="end"
                                                    >
                                                        {showPassword[index] ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            style: { height: '64px' } // Set consistent height
                                        }}
                                    />
                                    {(isRegistration || isEdit) && (
                                        <Typography variant="body2" sx={{ mt: 1 }}>
                                            {passwordCriteria}
                                        </Typography>
                                    )}
                                </Box>
                            ) : placeholders[index]?.toLowerCase().includes('date') ? (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label={placeholders[index] || `Input ${index + 1}`}
                                        inputFormat="MM/dd/yyyy"
                                        value={formData[index] ? new Date(formData[index]) : null}
                                        onChange={(date) => handleDateChange(index, date)}
                                        textField={(params) => <TextField {...params} variant="outlined" fullWidth InputProps={{ style: { height: '64px' } }} />}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <TextField
                                    type="text"
                                    id={`input-${index}`}
                                    name={`input-${index}`}
                                    value={formData[index] || ""}
                                    label={placeholders[index] || `Input ${index + 1}`}
                                    placeholder={placeholders[index]?.toLowerCase().includes('phone') ? "(   )-   -    " : ''}
                                    onChange={(event) => handleInputChange(index, event)}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        style: { height: '64px' } // Set consistent height
                                    }}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>
                {isEdit && (
                    <Box mt={2} display="flex" alignItems="center">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="upload-file"
                        />
                        <label htmlFor="upload-file">
                            <Button variant="outlined" color="primary" component="span">
                                Select An Image
                            </Button>
                        </label>
                        {preview && (
                            <Box ml={2}>
                                <img
                                    src={preview}
                                    alt="Preview"
                                    style={{ width: '100px', height: '100px', objectFit: 'contain', borderRadius: '8px' }}
                                />
                            </Box>
                        )}
                    </Box>
                )}
                <Box mt={2}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default DynamicInput;