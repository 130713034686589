import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {Layout, Loading} from './components';
import * as permissionTypes from './utilities/permissionNames';
import { getCookie } from './utilities/cookieUtils';
import { hasPermissions } from './utilities/permissionUtils';
import './styles/global.scss';

// Lazy load components using dynamic import paths
const EmployeeDetails = lazy(() => import('./pages').then(module => ({ default: module.EmployeeDetails })));
const Employees = lazy(() => import('./pages').then(module => ({ default: module.Employees })));
const EmployeesLogs = lazy(() => import('./pages').then(module => ({ default: module.EmployeesLogs })));
const Home = lazy(() => import('./pages').then(module => ({ default: module.Home })));
const Locations = lazy(() => import('./pages').then(module => ({ default: module.Locations })));
const Login = lazy(() => import('./pages').then(module => ({ default: module.Login })));
const LotInfoDetails = lazy(() => import('./pages').then(module => ({ default: module.LotInfoDetails })));
const LotNumberByLocationHistories = lazy(() => import('./pages').then(module => ({ default: module.LotNumberByLocationHistories })));
const Lots = lazy(() => import('./pages').then(module => ({ default: module.Lots })));
const LotsLocation = lazy(() => import('./pages').then(module => ({ default: module.LotsLocation })));
const NotFound = lazy(() => import('./pages').then(module => ({ default: module.NotFound })));
const RolesPermissions = lazy(() => import('./pages').then(module => ({ default: module.RolesPermissions })));
const PoTypeDetails = lazy(() => import('./pages').then(module => ({ default: module.PoTypeDetails })));
const PoTypes = lazy(() => import('./pages').then(module => ({ default: module.PoTypes })));
const PriceLists = lazy(() => import('./pages').then(module => ({ default: module.PriceLists })));
const Prices = lazy(() => import('./pages').then(module => ({ default: module.Prices })));
const Product = lazy(() => import('./pages').then(module => ({ default: module.Product })));
const Products = lazy(() => import('./pages').then(module => ({ default: module.Products })));
const ProductsLogs = lazy(() => import('./pages').then(module => ({ default: module.ProductsLogs })));
const Profile = lazy(() => import('./pages').then(module => ({ default: module.Profile })));
const PurchaseOrderDetails = lazy(() => import('./pages').then(module => ({ default: module.PurchaseOrderDetails })));
const PurchaseOrders = lazy(() => import('./pages').then(module => ({ default: module.PurchaseOrders })));
const Register = lazy(() => import('./pages').then(module => ({ default: module.Register })));
const Returns = lazy(() => import('./pages').then(module => ({ default: module.Returns })));
const StockTransferDetails = lazy(() => import('./pages').then(module => ({ default: module.StockTransferDetails })));
const StockTransfers = lazy(() => import('./pages').then(module => ({ default: module.StockTransfers })));
const RolePermissions = lazy(() => import('./pages').then(module => ({ default: module.RolePermissions })));

const App = () => {
    const { isAuthenticated, userPermissions = [] } = useSelector(state => state.auth);
    const adminInfo = JSON.parse(getCookie('employee'));
    const isAdmin = adminInfo && adminInfo.job_title === 'Root';
    
    const getAuthenticatedRoutes = () => [
        {
            path: '/',
            element: <Layout />,
            children: [
                { path: '/', element: <Home /> },
                isAdmin && { path: '/admin/register', element: <Register /> },
                isAdmin && { path: '/admin/roles_permissions', element: <RolesPermissions /> },
                isAdmin && { path: '/admin/roles_permissions/:roleId', element: <RolePermissions /> },
                (hasPermissions(userPermissions, permissionTypes.STAFF_PERMISSIONS) || isAdmin) && { path: '/employees', element: <Employees /> },
                { path: '/profile', element: <Profile /> },
                isAdmin && { path: '/admin/employees/:employeeId', element: <EmployeeDetails /> },
                hasPermissions(userPermissions, permissionTypes.STAFF_PERMISSIONS) && { path: '/products/:locationName', element: <Products /> },
                hasPermissions(userPermissions, permissionTypes.STAFF_PERMISSIONS) && { path: '/:locationName/products/:sku', element: <Product /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ) && { path: '/locations', element: <Locations userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ) && { path: '/lots', element: <Lots userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ) && { path: '/lots/:lotNumber/:SKU', element: <LotInfoDetails userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ) && { path: '/stock_transfers', element: <StockTransfers userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ) && { path: '/stock_transfers/:transferNumber', element: <StockTransferDetails userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ) && { path: '/lots_locations/:locationName', element: <LotsLocation userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ) && { path: '/lots_locations/:locationName/:lotNumber', element: <LotNumberByLocationHistories userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.SALES_READ) && { path: '/prices', element: <Prices userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.SALES_READ) && { path: '/po_types', element: <PoTypes userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.SALES_READ) && { path: '/po_types/:poTypeId/:poTypeName', element: <PoTypeDetails userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.SALES_READ) && { path: '/price_lists', element: <PriceLists userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.SALES_READ) && { path: '/purchase_orders', element: <PurchaseOrders userPermissions={userPermissions} /> },
                hasPermissions(userPermissions, permissionTypes.INVENTORY_READ, permissionTypes.SALES_READ) && { path: '/purchase_orders/:orderNumber', element: <PurchaseOrderDetails userPermissions={userPermissions} /> },
                { path: '/returns', element: <Returns /> },
                { path: '/products_logs', element: <ProductsLogs /> },
                { path: '/employees_logs', element: <EmployeesLogs /> },
            ].filter(Boolean)
        },
        {
            path: '/login',
            element: <Navigate to="/" />
        },
        {
            path: '*',
            element: <NotFound />
        }
    ];
    
    const getUnauthenticatedRoutes = () => [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '*',
            element: <Navigate to="/login" />
        }
    ];
    
    const routes = isAuthenticated ? getAuthenticatedRoutes() : getUnauthenticatedRoutes();
    const router = createBrowserRouter(routes);
    
    return (
        <Suspense fallback={<Loading message={"Loading..."} />}>
            <RouterProvider router={router} />
        </Suspense>
    );
};

export default App;