// Auth
const AUTH_PREFIX = 'auth/';
export const LOGIN_REQUEST = `${AUTH_PREFIX}login/request`;
export const LOGIN_SUCCESS = `${AUTH_PREFIX}login/success`;
export const LOGIN_FAILURE = `${AUTH_PREFIX}login/error`;
export const SET_TOKEN_AND_EMPLOYEE_FROM_COOKIE = `${AUTH_PREFIX}set_tokens_and_employee_from_cookie`;
export const SET_TOKENS = `${AUTH_PREFIX}set_tokens`;
export const SET_EMPLOYEE = `${AUTH_PREFIX}set_employee`;
export const SET_USER_PERMISSIONS = `${AUTH_PREFIX}set_user_permissions`;
export const UPDATE_ACCESS_TOKEN = `${AUTH_PREFIX}update_access_token`;
export const LOGOUT = `${AUTH_PREFIX}/logout`;

export const ADD_NOTIFICATION = 'notifications/add';
export const REMOVE_NOTIFICATION = 'notifications/remove';
export const CLEAR_NOTIFICATIONS = 'notifications/clear';
export const SET_SUCCESS_NOTIFICATION = 'notifications/success/set';
export const SET_ERROR_NOTIFICATION = 'notifications/error/set';
export const CLEAR_SUCCESS_NOTIFICATION = 'notifications/success/clear';
export const CLEAR_ERROR_NOTIFICATION = 'notifications/error/clear';

// Products
const PRODUCTS_PREFIX = 'products/';
export const FETCH_PRODUCTS_REQUEST = `${PRODUCTS_PREFIX}fetch/request`;
export const FETCH_PRODUCTS_SUCCESS = `${PRODUCTS_PREFIX}fetch/success`;
export const FETCH_PRODUCTS_ERROR = `${PRODUCTS_PREFIX}fetch/error`;