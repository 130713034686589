import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import refreshToken from "../../api/tokenRefreshService";
import {logout} from "../../actions/authActions";
import api from "../../api/api";

const TokenRefreshManager = () => {
    const dispatch = useDispatch();
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());
    const [showLogoutWarning, setShowLogoutWarning] = useState(false);
    const [remainingTime, setRemainingTime] = useState(60); // Initial remaining time
    
    useEffect(() => {
        const refreshInterval = (8 * 60 * 1000) - (60 * 1000); // Refresh token every 7 minutes, with a 1-minute buffer
        let intervalId;
        
        const refreshAccessToken = async () => {
            try {
                await refreshToken(dispatch);
                setLastActivityTime(Date.now());
            } catch (error) {
                console.error("Error refreshing token:", error);
            }
        };
        
        const startTokenRefreshInterval = () => {
            intervalId = setInterval(refreshAccessToken, refreshInterval);
        };
        
        const checkLogoutTimer = async () => {
            const logoutTimeout = 15 * 60 * 1000; // 1 minute
            const timeSinceLastActivity = Date.now() - lastActivityTime;
            if (timeSinceLastActivity >= logoutTimeout) {
                try {
                    await api.post('/auth/logout'); // Assuming `api` is your API client
                } catch (error) {
                    console.error("Error logging out:", error);
                }
                dispatch(logout());
            } else if (timeSinceLastActivity >= logoutTimeout - 60000) {
                // Display the warning 60 seconds before logout
                setShowLogoutWarning(true);
                setRemainingTime(60); // Reset the remaining time to 60 seconds
            }
        };
        
        startTokenRefreshInterval();
        
        // Set up interval to check for user inactivity
        const displayTimerInterval = setInterval(checkLogoutTimer, 1000); // Check every second
        
        // Cleanup function to clear interval when the component unmounts
        return () => {
            clearInterval(intervalId);
            clearInterval(displayTimerInterval);
        };
    }, [dispatch, lastActivityTime]);
    
    // Event listener to update last activity time on user activity
    useEffect(() => {
        const handleUserActivity = () => {
            setLastActivityTime(Date.now());
            setShowLogoutWarning(false); // Reset warning when user is active
        };
        
        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("keydown", handleUserActivity);
        
        return () => {
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("keydown", handleUserActivity);
        };
    }, []);
    
    return (
        <>
            {showLogoutWarning && window.confirm(`You have ${remainingTime} seconds left to logout before you are automatically logged out due to inactivity.`)}
        </>
    );
};

export default TokenRefreshManager;