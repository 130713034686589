import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/rootReducer';
import { getCookie } from "../utilities/cookieUtils";
import { setTokenAndEmployeeFromCookie } from "../actions/authActions";

// In initialState, retrieve both token and employee from cookie if available
const accessTokenFromCookie = getCookie('accessToken');
const refreshTokenFromCookie = getCookie('refreshToken');
const employeeFromCookie = JSON.parse(getCookie('employee'));
const permissionsFromCookie = JSON.parse(getCookie('permissions'));

// Set up the initial state of the store
const initialState = {
    auth: {
        isAuthenticated: Boolean(employeeFromCookie && permissionsFromCookie && accessTokenFromCookie && refreshTokenFromCookie),
        employee: employeeFromCookie,
        accessToken: accessTokenFromCookie,
        refreshToken: refreshTokenFromCookie,
        userPermissions: permissionsFromCookie,
        loading: false,
        error: null
    },
    notification: {
        notifications: [],
        success: null,
        error: null
    },
    products: {
        products: [],
        success: null,
        error: null
    }
    // other initial states for different slices if needed
};

// Create the Redux store
const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

// If there's a token in the cookie, dispatch action to set both token and employee in the Redux store
if (accessTokenFromCookie && employeeFromCookie && refreshTokenFromCookie && permissionsFromCookie) {
    store.dispatch(setTokenAndEmployeeFromCookie());
}

export default store;