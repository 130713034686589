import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './search.scss';

const Search = ({ options, onChange, message = 'Search...' }) => {
    return (
        <Autocomplete
            className="search-bar"
            options={options}
            onChange={(event, value) => onChange(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={message}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        classes: { notchedOutline: 'no-outline' }
                    }}
                />
            )}
            clearOnEscape
        />
    );
};

export default Search;