import {combineReducers} from '@reduxjs/toolkit';
import authReducer from './authReducer';
import notificationReducer from "./notificationReducer";
import productsReducer from "./productsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    notification: notificationReducer,
    products: productsReducer,
});

export default rootReducer;
