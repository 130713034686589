import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#2e3b4e',
    padding: '20px 0',
}));

const FooterContent = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const Logo = styled('img')({
    height: '30px',
    marginRight: '10px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const FooterText = styled(Typography)({
    color: '#fff',
});

const PoweredBy = styled(Typography)({
    color: '#b0b0b0',
    display: 'inline',
    fontStyle: 'italic',
});

const Footer = () => {
    return (
        <FooterContainer component="footer">
            <Container>
                <FooterContent>
                    <Link to="/" className="logo">
                        <Logo src="/logo.png" alt="Company Logo" />
                    </Link>
                    <FooterText variant="body2">
                        © 2024 Wide Naturals Inc. All Rights Reserved.{' '}
                        <PoweredBy component="span">Powered by Bob Dev</PoweredBy>
                    </FooterText>
                </FooterContent>
            </Container>
        </FooterContainer>
    );
};

export default Footer;