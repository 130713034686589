import axios from "axios";
import store from "../redux/store";
import {setCookie} from "../utilities/cookieUtils";

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '/api/v1' : process.env.REACT_APP_DEV_BASE_URL,
    withCredentials: true, // Include cookies with cross-origin requests
});

// Axios interceptor for setting token as cookie
instance.interceptors.request.use((config) => {
    const {accessToken} = store.getState().auth;
    
    if (accessToken) {
        // Set the token as a cookie
        setCookie('accessToken', accessToken);
    }
    
    return config;
});

export default instance;