const isValidPassword = (password) => {
    if (typeof password !== 'string') {
        return false;
    }
    return password.length >= 8 &&
        password.length <= 32 &&
        /[a-zA-Z]/.test(password) &&
        /\d/.test(password) &&
        /[!@#$%^&*()\-_=+{};:,<.>]/.test(password);
};

module.exports = {isValidPassword};