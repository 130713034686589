import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Backdrop from '@mui/material/Backdrop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome, faUsers, faClipboardList, faWarehouse, faFileAlt, faTags, faDolly, faMapMarkedAlt, faListAlt, faReceipt, faUndo, faUserPlus, faKey, faTasks, faDollarSign, faSitemap, faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';
import { getCookie } from "../../utilities/cookieUtils";
import { hasPermissions } from "../../utilities/permissionUtils";
import * as permissionTypes from "../../utilities/permissionNames";

const Sidebar = ({ open, closeDrawer }) => {
    const { userPermissions } = useSelector(state => state.auth);
    const adminInfo = JSON.parse(getCookie('employee'));
    const isAdmin = adminInfo && adminInfo.job_title === "Root";
    
    const sidebarData = [
        {
            id: 1,
            title: "Admin",
            listItems: [
                { id: 1, title: "Register", url: "/admin/register", icon: faUserPlus, permissions: [isAdmin] },
                { id: 2, title: "Employees", url: "/employees", icon: faUsers, permissions: [isAdmin] },
                { id: 3, title: "Roles & Permissions", url: "/admin/roles_permissions", icon: faKey, permissions: [isAdmin] },
                { id: 4, title: "Assign Permissions", url: "/assign_permissions", icon: faTasks, permissions: [isAdmin] },
                { id: 5, title: "Activity Log", url: "/employees_logs", icon: faClipboardList, permissions: [isAdmin] },
            ],
        },
        {
            id: 2,
            title: "Dashboard",
            listItems: [
                { id: 1, title: "Dashboard", url: "/", icon: faHome, permissions: [permissionTypes.STAFF_PERMISSIONS] },
                { id: 2, title: "Dashboard Related", url: "/dashboard_related", icon: faHome, permissions: [permissionTypes.STAFF_PERMISSIONS] },
            ],
        },
        {
            id: 3,
            title: "General Info",
            listItems: [
                { id: 1, title: "Employees", url: "/employees", icon: faUsers, permissions: [permissionTypes.STAFF_PERMISSIONS] },
                { id: 2, title: "Products", url: "/products/Office", icon: faTags, permissions: [permissionTypes.STAFF_PERMISSIONS] },
            ],
        },
        {
            id: 4,
            title: "Inventory",
            listItems: [
                { id: 1, title: "Locations", url: "/locations", icon: faLocationArrow, permissions: [permissionTypes.INVENTORY_READ] },
                { id: 2, title: "Lots", url: "/lots", icon: faWarehouse, permissions: [permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ] },
                { id: 3, title: "Stock Transfers", url: "/stock_transfers", icon: faDolly, permissions: [permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ] },
                { id: 4, title: "Lots Locations", url: "/lots_locations/Office", icon: faMapMarkedAlt, permissions: [permissionTypes.INVENTORY_READ, permissionTypes.MANUFACTURING_READ] },
            ],
        },
        {
            id: 5,
            title: "Sales",
            listItems: [
                { id: 1, title: "Prices", url: "/prices", icon: faDollarSign, permissions: [permissionTypes.SALES_READ] },
                { id: 2, title: "Price Lists", url: "/price_lists", icon: faListAlt, permissions: [permissionTypes.SALES_READ] },
                { id: 3, title: "Po Types", url: "/po_types", icon: faSitemap, permissions: [permissionTypes.SALES_READ] },
                { id: 4, title: "Purchase Orders", url: "/purchase_orders", icon: faReceipt, permissions: [permissionTypes.SALES_READ, permissionTypes.INVENTORY_READ] },
                { id: 5, title: "Returns", url: "/returns", icon: faUndo, permissions: [permissionTypes.SALES_READ, permissionTypes.INVENTORY_READ] },
            ],
        },
        {
            id: 6,
            title: "Logs",
            listItems: [
                { id: 1, title: "Product Quantity Changes", url: "/products_logs", icon: faFileAlt, permissions: [permissionTypes.INVENTORY_READ] },
                { id: 2, title: "Activity Log", url: "/employees_logs", icon: faClipboardList, permissions: [permissionTypes.INVENTORY_READ] },
            ],
        },
    ];
    
    const filteredSidebarData = isAdmin
        ? sidebarData.filter(item => item.title === "Admin")
        : sidebarData.filter(item =>
            item.title !== "Admin" &&
            item.listItems.some(listItem => hasPermissions(userPermissions, ...listItem.permissions))
        );
    
    return (
        <>
            <Backdrop open={open} onClick={closeDrawer} sx={{ zIndex: (theme) => theme.zIndex.drawer - 1, backgroundColor: 'transparent'}} />
            <Drawer variant="persistent" anchor="left" open={open} sx={{ flexShrink: 0, '& .MuiDrawer-paper': { boxSizing: 'border-box', position: 'fixed', top: 0, left: 0, backgroundColor: '#2c3e50', color: '#ecf0f1', height: '100%', borderRight: 'none', padding: 0, width: '250px' } }}>
                <Box sx={{ overflow: 'auto', backgroundColor: '#2c3e50', color: '#ecf0f1', height: '100%' }}>
                    <List sx={{ paddingTop: 10 }}>
                        {filteredSidebarData && filteredSidebarData.length > 0 && filteredSidebarData.map(item => (
                            <Fragment key={item.id}>
                                <Typography variant="h6" sx={{ paddingLeft: 2, paddingTop: 2, color: '#1abc9c' }}>
                                    {item.title.toUpperCase()}
                                </Typography>
                                <Divider sx={{ backgroundColor: '#1abc9c' }} />
                                {item.listItems && item.listItems?.map((listItem, index) => (
                                    (isAdmin && listItem.permissions?.includes(true)) || hasPermissions(userPermissions, ...listItem.permissions) ? (
                                        <ListItem component={RouterLink} to={listItem.url} key={index} sx={{ color: '#ecf0f1' }}>
                                            <ListItemIcon sx={{ color: '#ecf0f1' }}>
                                                <FontAwesomeIcon icon={listItem.icon} />
                                            </ListItemIcon>
                                            <ListItemText primary={listItem.title} />
                                        </ListItem>
                                    ) : null
                                ))}
                                <Divider sx={{ backgroundColor: '#1abc9c' }} />
                            </Fragment>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default Sidebar;