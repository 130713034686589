import api from "./api";

export const fetchAllEmployees = async () => {
    try {
        const response = await api.get("/employees");
        if (response.status !== 200) {
            throw new Error('Failed to fetch employees.');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching all employees:', error.message);
        throw error;
    }
};

export const fetchEmployeeProfileInfo = async (employeeId) => {
    try {
        const response = await api.get(`/employees/profile_info/${employeeId}`);
        if (response.status !== 200) {
            throw new Error('Failed to fetch employee info.');
        }
        return response.data.data;
    } catch (error) {
        console.error('Error fetching employee info:', error.message);
        throw error;
    }
};

export const editProfile = async (employeeId, file, jsonString) => {
    const response = await api.put(`/employees/profile_info/${employeeId}`, {
            image_data: file,
            employee_info: jsonString
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    
    if (response.status !== 200) {
        throw new Error('Failed to update profile.');
    }
    
    return response.data;
};