import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Loading = ({ message = 'Loading...' }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            bgcolor="#f0f0f0"
        >
            <CircularProgress
                size={50}
                sx={{
                    border: '5px solid #f3f3f3',
                    borderTop: '5px solid #3498db',
                    borderRadius: '50%',
                    animation: 'spin 1s linear infinite',
                    mb: 2,
                }}
            />
            <Typography
                variant="h6"
                sx={{
                    fontSize: '1.2em',
                    fontWeight: 'bold',
                    color: '#555',
                }}
            >
                {message}
            </Typography>
            <style>{`
                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
        </Box>
    );
};

export default Loading;