import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';

const PaginationItem = ({ count, page, onChange }) => {
    return (
        <Container>
            <Pagination
                count={count}
                page={page}
                onChange={onChange}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
            />
        </Container>
    );
};

export default PaginationItem;