import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import AsyncSelect from 'react-select/async';

const DynamicDatasetForm = ({ values = [], dropdownLists = [], onSubmit, productOptions, onInputChange }) => {
    const [datasets, setDatasets] = useState([]);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        if (values && values.length > 0) {
            setDatasets(values.map((data, index) => ({ id: index + 1, data })));
        } else {
            setDatasets([{ id: 1, data: {} }]);
        }
    }, [values]);
    
    const [datasetCount, setDatasetCount] = useState(values ? values.length : 1);
    
    const handleInputChange = (datasetIndex, fieldName, value) => {
        const updatedDatasets = [...datasets];
        if (fieldName === 'productName_SKU') {
            updatedDatasets[datasetIndex].data.product_name = value.product_name;
            updatedDatasets[datasetIndex].data.sku = value.sku;
        } else {
            updatedDatasets[datasetIndex].data[fieldName] = value;
        }
        setDatasets(updatedDatasets);
        onInputChange(datasetIndex, fieldName, value);
    };
    
    const handleAddDataset = () => {
        const newDatasetCount = datasetCount + 1;
        setDatasetCount(newDatasetCount);
        const newDataset = { id: newDatasetCount, data: {} };
        if (datasets.length > 0) {
            Object.keys(datasets[0].data).forEach(key => {
                newDataset.data[key] = '';
            });
        }
        setDatasets([...datasets, newDataset]);
    };
    
    const handleRemoveDataset = (index) => {
        const updatedDatasets = datasets.filter((_, i) => i !== index);
        setDatasets(updatedDatasets);
        setDatasetCount(updatedDatasets.length);
    };
    
    const handleSubmit = () => {
        onSubmit(datasets);
        setDatasets(values ? values.map((data, index) => ({ id: index + 1, data })) : [{ id: 1, data: {} }]);
    };
    
    const formatFieldName = (fieldName) => {
        return fieldName.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };
    
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const getOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(productOptions?.filter(i =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                ));
            }, 1000);
        });
    
    const getDefaultExpirationDate = () => {
        const today = new Date();
        return new Date(today.setFullYear(today.getFullYear() + 3));
    };
    
    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Add Lots
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ background: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)', maxHeight: '90vh', overflowY: 'auto', maxWidth: '800px', margin: 'auto' }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Add Lots
                    </Typography>
                    {datasets && datasets?.map((dataset, index) => (
                        <Box key={`${dataset.id}-${index}`} sx={{ marginBottom: '20px', position: 'relative', zIndex: 0 }}>
                            <FormControl fullWidth margin="normal" sx={{ zIndex: 2 }}>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={getOptions}
                                    onChange={(selectedOption) => handleInputChange(index, 'productName_SKU', selectedOption.value)}
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            minHeight: '56px',
                                            zIndex: 2,
                                        }),
                                        menu: (base) => ({
                                            ...base,
                                            zIndex: 3,
                                        }),
                                    }}
                                    placeholder="Select Product"
                                />
                            </FormControl>
                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                                <TextField
                                    label="Lot Qty"
                                    placeholder="Lot Qty"
                                    type="number"
                                    value={dataset.data.lot_qty || ''}
                                    onChange={(e) => handleInputChange(index, 'lot_qty', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    sx={{ zIndex: 1 }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Expiration Date"
                                        value={dataset.data.expiration_date || getDefaultExpirationDate()}
                                        onChange={(date) => handleInputChange(index, 'expiration_date', date)}
                                        slotProps={{ textField: { fullWidth: true, margin: "normal" } }}
                                    />
                                </LocalizationProvider>
                                <TextField
                                    label="Unit Price"
                                    placeholder="Unit Price"
                                    type="number"
                                    value={dataset?.data.unit_price || ''}
                                    onChange={(e) => handleInputChange(index, 'unit_price', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    sx={{ zIndex: 1 }}
                                />
                                <TextField
                                    label="Manufacturer"
                                    placeholder="Manufacturer"
                                    value={dataset?.data.manufacturer || ''}
                                    onChange={(e) => handleInputChange(index, 'manufacturer', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    sx={{ zIndex: 1 }}
                                />
                            </Box>
                            <TextField
                                label="Note"
                                placeholder="Note"
                                value={dataset?.data.note || ''}
                                onChange={(e) => handleInputChange(index, 'note', e.target.value)}
                                multiline
                                rows={2}
                                fullWidth
                                margin="normal"
                                sx={{ zIndex: 1 }}
                            />
                            {dropdownLists && dropdownLists?.map((dropdown, i) => (
                                <FormControl key={i} fullWidth margin="normal">
                                    <InputLabel>{formatFieldName(dropdown?.fieldName)}</InputLabel>
                                    <Select
                                        value={dataset?.data[dropdown?.fieldName] || ''}
                                        onChange={(e) => handleInputChange(index, dropdown?.fieldName, e.target.value)}
                                        label={formatFieldName(dropdown?.fieldName)}
                                    >
                                        <MenuItem value=""><em>Select Locations</em></MenuItem>
                                        {dropdown?.value.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ))}
                            {datasets?.length > 1 && (
                                <Button variant="contained" color="secondary" onClick={() => handleRemoveDataset(index)} sx={{ marginTop: '10px', width: '100%', backgroundColor: '#ff4d4f', color: '#fff', '&:hover': { backgroundColor: '#d32f2f' } }}>
                                    Remove
                                </Button>
                            )}
                        </Box>
                    ))}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                        <Button variant="contained" color="primary" onClick={handleAddDataset} sx={{ width: '48%' }}>
                            Add Lot
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ width: '48%' }}>
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default DynamicDatasetForm;