import * as actionTypes from "./actionTypes";
import { clearStorage, getCookie, setCookie } from "../utilities/cookieUtils";

// Action creator to login user
export const login = (loginData) => {
    return async (dispatch) => {
        dispatch(loginRequest());  // Ensure this is dispatched first
        try {
            // Your existing login logic
            const { accessToken, refreshToken, employee, userPermissions } = loginData;
            
            // Dispatch action for successful login
            dispatch(loginSuccess({ accessToken, refreshToken, employee, userPermissions }));
            
            // Set tokens and employee info in Redux store
            dispatch(setTokens(accessToken, refreshToken));
            dispatch(setEmployee(employee));
            dispatch(setUserPermissions(userPermissions));
            
            // Set token and employee info as cookies
            setCookie('accessToken', accessToken);
            setCookie('refreshToken', refreshToken);
            setCookie('employee', JSON.stringify(employee));
            setCookie('permissions', JSON.stringify(userPermissions));
            
            // Dispatch action to set token and employee from cookie
            dispatch(setTokenAndEmployeeFromCookie());
        } catch (error) {
            // Dispatch action for login failure
            dispatch(loginFailure(error.message || "Failed to log in. Please try again later."));
        }
    };
};

export const loginRequest = () => ({
    type: actionTypes.LOGIN_REQUEST,
});

export const loginSuccess = (loginData) => ({
    type: actionTypes.LOGIN_SUCCESS,
    payload: loginData,
});

export const loginFailure = (error) => ({
    type: actionTypes.LOGIN_FAILURE,
    payload: error,
});

export const setTokenAndEmployeeFromCookie = () => {
    return async (dispatch) => {
        try {
            const accessToken = getCookie('accessToken');
            const refreshToken = getCookie('refreshToken');
            const employee = JSON.parse(getCookie('employee'));
            const permissionsStr = getCookie('permissions');
            const userPermissions = permissionsStr ? JSON.parse(permissionsStr) : null;
            
            if (accessToken && refreshToken && employee && userPermissions) {
                dispatch({
                    type: actionTypes.SET_TOKEN_AND_EMPLOYEE_FROM_COOKIE,
                    payload: { employee, accessToken, refreshToken, userPermissions }
                });
            } else {
                // Clear the Redux store if token or employee info is not found in cookies
                dispatch(logout());
            }
        } catch (error) {
            console.error("Error retrieving token, employee, or permissions from cookies:", error);
            // Handle error if necessary
            // Clear the Redux store in case of any error while retrieving cookies
            dispatch(logout());
        }
    };
};

export const setTokens = (accessToken, refreshToken) => ({
    type: actionTypes.SET_TOKENS,
    payload: { accessToken, refreshToken }
});

export const setEmployee = (employee) => ({
    type: actionTypes.SET_EMPLOYEE,
    payload: { employee }
});

export const setUserPermissions = (userPermissions) => ({
    type: actionTypes.SET_USER_PERMISSIONS,
    payload: { userPermissions }
});

export const updateAccessToken = (accessToken) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.UPDATE_ACCESS_TOKEN, accessToken: accessToken });
        // Store the new access token in a cookie
        setCookie('accessToken', accessToken);
    };
};

// Add a logout action that clears both token and employee info from cookie
export const logout = () => {
    return (dispatch) => {
        // Clear all web storages
        clearStorage();
        
        // Dispatch action to update Redux store
        dispatch({ type: actionTypes.LOGOUT });
        
        // Redirect user to login page (optional)
        window.location.href = '/login'; // Redirect to the login page
    };
};