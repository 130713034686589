import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Footer, Header, SideBar, TokenRefreshManager } from "../index";

const Layout = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    
    const closeDrawer = () => {
        setDrawerOpen(false);
    };
    
    useEffect(() => {
        // Fetch and set products
        // Example: axios.get('/api/products').then(response => dispatch(setProducts(response.data)));
        
        // Fetch and set orders
        // Example: axios.get('/api/orders').then(response => dispatch(setOrders(response.data)));
    }, [dispatch]);
    
    return (
        <Box className="main" sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <TokenRefreshManager />
            <Header toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
                <Box sx={{
                    width: drawerOpen ? '150px' : '0',
                    transition: 'width 0.3s',
                    overflowX: 'hidden',
                    flexShrink: 0
                }}>
                    <SideBar open={drawerOpen} toggleDrawer={toggleDrawer} closeDrawer={closeDrawer} />
                </Box>
                <Box sx={{
                    flexGrow: 1,
                    transition: 'margin-left 0.3s',
                    marginLeft: drawerOpen ? '100px' : '0',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Box sx={{ flexGrow: 1, p: 3 }}>
                        <Outlet />
                    </Box>
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
};

export default Layout;