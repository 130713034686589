import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { clearNotifications } from '../../actions/notificationActions';

const Notification = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notification.notifications);
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleClearNotifications = () => {
        dispatch(clearNotifications());
        handleClose();
    };
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <Box display="inline-block" position="relative">
            <IconButton
                color="inherit"
                onClick={handleClick}
                sx={{
                    fontSize: '40px',
                    cursor: 'pointer',
                    color: '#ecf0f1',
                    transition: 'transform 0.3s ease, color 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.2)',
                        color: '#1abc9c',
                    },
                }}
            >
                <Badge badgeContent={notifications.length} color="secondary">
                    <NotificationsIcon sx={{ fontSize: '36px' }} />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: 400,
                            width: 300,
                            backgroundColor: '#2c3e50',
                            color: '#ecf0f1',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                            borderRadius: '5px',
                            zIndex: 1000,
                        }
                    }
                }}
            >
                {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                        <MenuItem
                            key={index}
                            sx={{
                                padding: '10px',
                                borderBottom: '1px solid #34495e',
                                '&:last-child': { borderBottom: 'none' },
                            }}
                        >
                            <Typography variant="body2">{notification.message}</Typography>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem>
                        <Typography variant="body2">No notifications</Typography>
                    </MenuItem>
                )}
                {notifications.length > 0 && (
                    <MenuItem onClick={handleClearNotifications}>
                        <Typography variant="body2" sx={{ color: '#e74c3c' }}>Clear All Notifications</Typography>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default Notification;