import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faUserCircle, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getCookie } from "../../utilities/cookieUtils";
import { fetchEmployeeProfileInfo } from "../../api/employeeApi";
import { Notification } from "../index";

const BlurBackground = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(5px)',
    zIndex: -1,
    display: 'none',
}));

const Logo = styled('img')(({ theme }) => ({
    width: '50px',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}));

const Header = ({ toggleDrawer, drawerOpen }) => {
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [scrolled, setScrolled] = useState(false);
    const employee = JSON.parse(getCookie("employee"));
    
    const fetchEmployeeInfo = useCallback(async () => {
        try {
            const employeeData = await fetchEmployeeProfileInfo(employee.employee_id);
            setEmployeeInfo(employeeData);
        } catch (error) {
            console.error('Error fetching employee info:', error.message);
        }
    }, [employee.employee_id]);
    
    useEffect(() => {
        fetchEmployeeInfo().catch(error => console.error('Error fetching employee info:', error.message));
        
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };
        
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fetchEmployeeInfo]);
    
    return (
        <AppBar position="sticky" sx={{ backgroundColor: scrolled ? 'rgba(44, 62, 80, 0.8)' : '#2c3e50', zIndex: 1300, height: 70 }}>
            {scrolled && <BlurBackground />}
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={toggleDrawer}
                        sx={{
                            mr: 2,
                            transition: 'transform 0.3s ease, color 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.2)',
                                color: '#1abc9c',
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={drawerOpen ? faTimes : faBars} size="lg" />
                    </IconButton>
                    <IconButton edge="start" color="inherit" component={RouterLink} to="/">
                        <Logo src="/logo.png" alt="Company Logo" sx={{ ml: 2 }} />
                    </IconButton>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Notification />
                    <IconButton color="inherit" component={RouterLink} to="/profile">
                        {employeeInfo && employeeInfo.image_url ? (
                            <Avatar src={employeeInfo.image_url} alt="Profile" sx={{ width: 40, height: 40 }} />
                        ) : (
                            <FontAwesomeIcon icon={faUserCircle} size="2x" />
                        )}
                    </IconButton>
                    <IconButton
                        color="inherit"
                        component={RouterLink}
                        to="/settings"
                        sx={{
                            transition: 'transform 0.3s ease, color 0.3s ease',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                color: '#1abc9c',
                            },
                        }}
                    >
                        <FontAwesomeIcon icon={faCogs} style={{ fontSize: '36px' }} />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;