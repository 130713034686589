import * as actionTypes from './actionTypes';

export const addNotification = (notification) => ({
    type: actionTypes.ADD_NOTIFICATION,
    payload: notification
});

export const removeNotification = (id) => ({
    type: actionTypes.REMOVE_NOTIFICATION,
    payload: id
});

export const clearNotifications = () => ({
    type: actionTypes.CLEAR_NOTIFICATIONS
});

export const setSuccessNotification = (message) => ({
    type: actionTypes.SET_SUCCESS_NOTIFICATION,
    payload: message
});

export const setErrorNotification = (message) => ({
    type: actionTypes.SET_ERROR_NOTIFICATION,
    payload: message
});

export const clearSuccessNotification = () => ({
    type: actionTypes.CLEAR_SUCCESS_NOTIFICATION
});

export const clearErrorNotification = () => ({
    type: actionTypes.CLEAR_ERROR_NOTIFICATION
});